import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Profile from '../routes/profile';
import About from '../routes/about';
import Contact from '../routes/contact';

export default class App extends Component {
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render() {
		return (
			<div>
			<div id="app">
				<Header />
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<About path="/about"></About>
					<Contact path="/contact"></Contact>
					<Profile path="/profile/:company"/>
				</Router>
			</div>
			<div style="text-align:center">
				<h3>Made with ❤️ in Auckland</h3>
				<h5>Prime Values - 2020</h5>
			</div>
			</div>
		);
	}
}
